import React from 'react';

export default () => (
    <React.Fragment>
        <h1>Privacy Policy</h1>
        <p>
            <strong>
                Effective date:
            </strong>
            &nbsp;September 3, 2024
        </p>
        <ol>
            <li>
                <h3>
                    Introduction
                </h3>
                <p>
                    Welcome to SilverBack Home Warranty ("we", "us", or "our"). We are committed to protecting your
                    privacy and ensuring that your personal information is handled in a safe and responsible manner.
                    This Privacy Policy outlines how we collect, use, and protect the information you provide to us.
                </p>
            </li>
            <li>
                <h3>
                    Information We Collect
                </h3>
                <p>
                    We collect personal information that you voluntarily provide to us when you sign up on our website
                    or communicate with us. The types of personal information we may collect include:
                </p>
                <ul>
                    <li>
                        <strong>
                            Email Address
                        </strong>
                    </li>
                    <li>
                        <strong>
                            Phone Number
                        </strong>
                    </li>
                </ul>
            </li>
            <li>
                <h3>
                    Use of Information
                </h3>
                <p>
                    We use the information we collect for the following purposes:
                </p>
                <ul>
                    <li>
                        To send you marketing communications, including newsletters, promotions, and updates.
                    </li>
                    <li>
                        To respond to your inquiries and provide customer support.
                    </li>
                    <li>
                        To comply with legal requirements.
                    </li>
                </ul>
                <p>
                    We do not sell, trade, or otherwise transfer your personal information to outside parties.
                </p>
                <p>
                    No mobile information will be shared with third parties/affiliates for marketing/promotional
                    purposes. All the above categories exclude text messaging originator opt-in data and consent; this
                    information will not be shared with any third parties.
                </p>
            </li>
            <li>
                <h3>
                    How We Share Your Data
                </h3>
                <p>
                    We may share your personal information with third-party service providers for the sole purpose of
                    assisting us in operating our website and conducting our business (e.g., processing payments,
                    sending emails, or providing customer service). These third parties are required to protect your
                    information and are not allowed to use it for any other purpose.
                </p>
                <p>
                    We do not sell, trade, or otherwise transfer your personal information to outside parties for
                    marketing or promotional purposes without your explicit consent.
                </p>
                <p>
                    All text messaging originator opt-in data and consent will not be shared with third parties.
                </p>
            </li>
            <li>
                <h3>
                    Marketing Communications
                </h3>
                <p>
                    By providing your email address and phone number, you consent to receive marketing communications
                    from us. Message frequency may vary depending on your interaction with our communications.
                </p>
            </li>
            <li>
                <h3>
                    Text Messaging Terms
                </h3>
                <ul>
                    <li>
                        Message frequency varies per user.
                    </li>
                    <li>
                        Message and data rates may apply.
                    </li>
                    <li>
                        Text HELP for help.
                    </li>
                    <li>
                        Text STOP to unsubscribe.
                    </li>
                    <li>
                        Carriers are not liable for delayed or undelivered messages.
                    </li>
                </ul>
            </li>
            <li>
                <h3>
                    Data Protection
                </h3>
                <p>
                    We implement a variety of security measures to maintain the safety of your personal information.
                    However, please be aware that no method of transmitting or storing data is completely secure, and we
                    cannot guarantee absolute security.
                </p>
            </li>
            <li>
                <h3>
                    Your Rights
                </h3>
                <p>
                    You have the right to:
                </p>
                <ul>
                    <li>
                        Opt-out of receiving marketing communications by following the instructions provided in our
                        communications.
                    </li>
                    <li>
                        Request access to the personal information we hold about you.
                    </li>
                    <li>
                        Request corrections to any inaccurate or incomplete information.
                    </li>
                    <li>
                        Request deletion of your personal information, subject to certain exceptions.
                    </li>
                </ul>
            </li>
            <li>
                <h3>
                    Changes to This Privacy Policy
                </h3>
                <p>
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page with an
                    updated revision date.
                </p>
            </li>
            <li>
                <h3>
                    Contact Us
                </h3>
                <p>
                    If you have any questions about this Privacy Policy or our practices, please contact us at <a
                    href="mailto:info@silverbackhw.com">info@silverbackhw.com</a>
                </p>
            </li>
        </ol>
    </React.Fragment>
);
