import React from "react";
import { func } from "prop-types";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";

import { KING_KONG } from "constants/plans";
import * as Styled from "./styled";
import PlanOption from "../plan-option";
import theme from "helpers/theme";

class PlanOptions extends React.Component {
  static propTypes = {
    onPlanSelection: func.isRequired,
  };

  handlePlanSelection = (plan, value) => {
    this.props.onPlanSelection(plan, value);
  };

  render() {
    const { t } = this.props;

    return (
      <Styled.Wrapper className={this.props.className} style={this.props.style}>
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.54)",
            fontSize: "3.5rem",
            lineHeight: "1.30357em",
            letterSpacing: "-.02em",
            [theme.breakpoints.down("md")]: {
              textAlign: "center",
              fontSize: 40,
            },
          }}
          className="title"
          variant="h3"
        >
          {t("plans:start.header")}{" "}
          <span
            style={{
              fontSize: "45px",
              letterSpacing: "1px",
              marginTop: "-2px",
            }}
          >
            {t("plans:start.subHeader")}
          </span>
        </Typography>
        <Styled.CardWrapper>
          <PlanOption
            className="plan-option"
            id="listing-coverage"
            title={t("plans:listingCoverage")}
            buttonLabel={t("plans:orderNow")}
            amount={0}
            onSelection={this.handlePlanSelection}
          />
          <PlanOption
            id="variable"
            className="plan-option"
            title={t("plans:variableAmount")}
            buttonLabel={t("plans:choosePlan")}
            onSelection={this.handlePlanSelection}
            minamount={450}
          />
          <PlanOption
            id={KING_KONG}
            className="plan-option"
            title={t("plans:mostPopular")}
            buttonLabel={t("plans:seePlans")}
            amount={700}
            onSelection={this.handlePlanSelection}
          />
        </Styled.CardWrapper>
      </Styled.Wrapper>
    );
  }
}

export default withTranslation(["common", "plans"])(PlanOptions);
