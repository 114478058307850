import React from 'react';

export default () => (
    <React.Fragment>
        <h1>Terms and conditions</h1>
        <p>
            <strong>
                Effective date:
            </strong>
            &nbsp;September 3, 2024
        </p>
        <ol>
            <li>
                <h3>
                    Introduction
                </h3>
                <p>
                    Welcome to the SilverBack Home Warranty website (the "Site"). By accessing or using this Site, you
                    agree to comply with and be bound by these Terms and Conditions ("Terms"). Please read these Terms
                    carefully before using the Site.
                </p>
            </li>
            <li>
                <h3>
                    Website Use
                </h3>
                <p>
                    By accessing the Site, you agree to use it only for lawful purposes and in a manner that does not
                    infringe the rights of others or restrict or inhibit their use and enjoyment of the Site.
                    Unauthorized use of this Site may give rise to a claim for damages and/or be a criminal offense.
                </p>
            </li>
            <li>
                <h3>
                    Intellectual Property
                </h3>
                <p>
                    All content, including but not limited to text, graphics, logos, images, and software on this Site,
                    is the property of SilverBack Home Warranty or its content providers and is protected by
                    intellectual property laws. Unauthorized reproduction or use of the content without prior written
                    consent is prohibited.
                </p>
            </li>
            <li>
                <h3>
                    Ordering Services
                </h3>
                <ul>
                    <li>
                        When ordering services through our Site, you are required to provide accurate and complete
                        information. Any false or misleading information may result in cancellation of the order.
                    </li>
                    <li>
                        Prices for services are subject to change without notice, and any changes will be reflected on
                        the Site at the time of ordering.
                    </li>
                    <li>
                        By placing an order through the Site, you agree to pay the indicated fees for the selected
                        services.
                    </li>
                </ul>
            </li>
            <li>
                <h3>
                    Communication Opt-Out:
                </h3>
                <ul>
                    <li>
                        You may receive service-related messages, including order confirmations and updates. Reply STOP
                        to opt-out of future messages, and reply HELP for assistance.
                    </li>
                    <li>
                        Message frequency may vary based on your activity.
                    </li>
                    <li>
                        Data rates may apply, depending on your carrier.
                    </li>
                    <li>
                        Carriers are not liable for undelivered messages.
                    </li>
                </ul>
            </li>
            <li>
                <h3>
                    Privacy
                </h3>
                <p>
                    Your use of the Site is also governed by our Privacy Policy, which outlines how we collect, use, and
                    protect your personal information. By using the Site, you consent to the practices described in the
                    Privacy Policy.
                </p>
            </li>
            <li>
                <h3>
                    Third-Party Links
                </h3>
                <p>
                    The Site may contain links to third-party websites. These links are provided for your convenience
                    only, and SilverBack Home Warranty does not endorse or assume responsibility for the content or
                    practices of these third-party sites. Accessing third-party links is at your own risk.
                </p>
            </li>
            <li>
                <h3>
                    Limitation of Liability
                </h3>
                <p>
                    SilverBack Home Warranty does not warrant that the Site will be error-free, uninterrupted, or free
                    of viruses or other harmful components. We are not liable for any damages arising from your use of
                    the Site, including but not limited to direct, indirect, incidental, or consequential damages.
                </p>
            </li>
            <li>
                <h3>
                    Changes to the Site and Terms
                </h3>
                <p>
                    SilverBack Home Warranty reserves the right to make changes or updates to the Site and these Terms
                    at any time without prior notice. Your continued use of the Site following any changes indicates
                    acceptance of the updated Terms.
                </p>
            </li>
            <li>
                <h3>
                    User Accounts
                </h3>
                <p>
                    If you create an account on our Site, you are responsible for maintaining the confidentiality of
                    your account information and password. You agree to accept responsibility for all activities that
                    occur under your account.
                </p>
            </li>
            <li>
                <h3>
                    Governing Law
                </h3>
                <p>
                    These Terms shall be governed by and construed in accordance with the laws of the state of Utah,
                    without regard to its conflict of law principles.
                </p>
            </li>
            <li>
                <h3>
                    Contact Information
                </h3>
                <p>
                    If you have any questions about these Terms, please contact us at:
                    <br/>
                    <strong> SilverBack Home Warranty </strong>
                    <br/>
                    <strong> Phone: </strong> 833-750-6400
                    <br/>
                    <strong> Email: </strong> <a href="mailto:info@silverbackhw.com">info@silverbackhw.com</a>
                </p>
            </li>
        </ol>
    </React.Fragment>
);
