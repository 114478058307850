export default {
  pageTitle: "Request Service",

  confirm: {
    title: "Thank You!",
    subtitle: `
      Your service request has been placed. We will be contacting you
      to gather additional details and arrange necessary service
      appointments.
    `,
  },
  property_popup: {
    title: "please select property",
    close: "Cancel",
  },
  waterHeater: {
    title: "Please select the type",
  },
  details: {
    title: "Details",
    serialNumber: "S/N",
    brand: "Brand",
    model: "Model",
  },
  rekey: {
    title: "Re-key:",
    locks: "Of locks",
    keys: "Of copy keys",
    upTo: "up to",
    time: {
      title: "Preferred Time of Day",
      earlyMorning: "Early Morning",
      lateMorning: "Late Morning",
      earlyAfternoon: "Early Afternoon",
      lateAfternoon: "Late Afternoon",
    },
  },
  coveragePackage: "Policy coverage package",
  contactInformation: "Contact Information",
  policyNumber: "Policy Number",
  propertyInformation: "Covered Property",
  services: {
    title: "Requested Services",
    disclaimer:
      "Note: Not all items below may be covered by your contract. Please check your contract before you place your service request.",
    optionTitles: [
      "Appliance",
      "Plumbing",
      "HVAC",
      "Electrical",
      "Miscellaneous",
    ],
    options: [
      {
        title: "A/C",
        category: 2,
      },
      {
        title: "Furnace",
        category: 2,
      },
      {
        title: "Evaporative (Swamp) Cooler",
        category: 2,
      },
      {
        title: "Window Unit",
        category: 2,
      },
      {
        title: "Thermostat",
        category: 2,
      },
      "Air Conditioning Repair",
      // {
      //   title: "Attic Fan",
      //   category: 3,
      // },
      {
        title: "Ceiling Fan",
        category: 3,
      },
      {
        title: "Central Vacuum System",
        category: 3,
      },
      {
        title: "Dishwasher",
        category: 0,
      },
      {
        title: "Circuit Breaker",
        category: 3,
      },
      {
        title: "Outlet/Switch/Plugs",
        category: 3,
      },
      {
        title: "Pre-Season Tune Up",
        category: 2,
      },
      {
        title: "Boiler System",
        category: 2,
      },
      {
        title: "Exhaust Fan",
        category: 0,
      },
      {
        title: "Garage Door Opener",
        category: 3,
      },
      {
        title: "Swimming Pool/Hot Tub",
        category: 4,
      },
      {
        title: "Garbage Disposal",
        category: 1,
      },
      {
        title: "Septic Tank System",
        category: 4,
      },
      {
        title: "Gas Leak",
        category: 1,
      },
      {
        title: "Drain Line/Sewer Stoppage",
        category: 1,
      },
      {
        title: "Hose Bibs",
        category: 1,
      },
      {
        title: "Interior",
        category: 1,
      },
      {
        title: "Exterior",
        category: 1,
      },
      {
        title: "Kitchen Refrigerator",
        category: 0,
      },
      {
        title: "Kitchen",
        category: 1,
      },
      {
        title: "Bathroom",
        category: 1,
      },
      {
        title: "Ice maker (Built-In)",
        category: 0,
      },
      {
        title: "Freestanding Ice maker",
        category: 0,
      },
      {
        title: "Oven/Range/Cooktop",
        category: 0,
      },
      {
        title: "Built-in Microwave",
        category: 0,
      },
      {
        title: "Built-in Oven",
        category: 0,
      },
      {
        title: "Washer",
        category: 0,
      },
      {
        title: "Dryer",
        category: 0,
      },
      {
        title: "Pipe Leak",
        category: 1,
      },
      {
        title: "Pressure Regulator",
        category: 1,
      },
      {
        title: "Toilet",
        category: 1,
      },
      {
        title: "Faucet/Fixture",
        category: 1,
      },
      {
        title: "Sump Pump",
        category: 1,
      },
      {
        title: "Trash Compactor",
        category: 0,
      },
      {
        title: "Water Heater",
        category: 1,
      },
      {
        title: "Water Softener",
        category: 1,
      },
      // {
      //   title: "Reverse Osmosis",
      //   category: 1,
      // },
      {
        title: "Well Pump",
        category: 1,
      },
      {
        title: "Sprinkler Timer & System",
        category: 4,
      },
    ],
  },
  description: "Description of Malfunction",
  detail: "Description of Service Request. When or how did this occur?",
  serviceCall: {
    title: "Service Call fee",
    subtitle: "Set your trade service call fee",
  },
};
