export const BLACKBACK = "Blackback";
export const SILVERBACK = "Silverback";
export const KING_KONG = "King Kong";
export const DUPLEX = "Duplex";
export const TRIPLEX = "Triplex";
export const FOURPLEX = "Fourplex";
export const NEW_HOUSE = "New Construction Single Family";
export const NEW_CONDO = "New Construction Condo/Townhome/Mobile Home";
export const NEW_HOUSE_BLACKBACK = "New Construction Single_Family/Blackback";
export const NEW_CONDO_BLACKBACK = "New Construction Condo_Townhome/Blackback";
export const NEW_HOUSE_SILVERBACK = "New Construction Single_Family/Silverback";
export const NEW_CONDO_SILVERBACK =
  "New Construction Condo_Townhome/Silverback";
export const NEW_HOUSE_KING_KONG = "New Construction Single_Family/King Kong";
export const NEW_CONDO_KING_KONG = "New Construction Condo_Townhome/King Kong";
export const REAL_ESTATE_CONDO_BLACKBACK = "Condo_Townhome/Blackback";
export const REAL_ESTATE_CONDO_SILVERBACK = "Condo_Townhome/Silverback";
export const REAL_ESTATE_CONDO_KING_KONG = "Condo_Townhome/King Kong";

export const ReferenceURL =
  "gs://silverback-website.appspot.com/Brochure-New.pdf";
export const Brochure =
  "https://firebasestorage.googleapis.com/v0/b/silverback-website.appspot.com/o/SBHWRealEstateBrochure.pdf?alt=media&token=2c1ddf96-9647-4e06-98df-d8c2b2d49589";
export const BrochureMobile =
  "https://firebasestorage.googleapis.com/v0/b/silverback-website.appspot.com/o/SBHWRealEstateBrochure-mobile.pdf?alt=media&token=ed1aee16-85f3-45b0-9fd1-1e1117cf73e1";
export const PlanPriceEnum = {
  "listing-coverage": 0,
  [BLACKBACK]: 450,
  [SILVERBACK]: 600,
  [REAL_ESTATE_CONDO_SILVERBACK]: 500,
  [REAL_ESTATE_CONDO_BLACKBACK]: 450,
  [REAL_ESTATE_CONDO_KING_KONG]: 700,
  [KING_KONG]: 700,
  [DUPLEX]: 795,
  [TRIPLEX]: 895,
  [FOURPLEX]: 995,
  [NEW_HOUSE]: 525,
  [NEW_CONDO]: 500,
  [NEW_HOUSE_BLACKBACK]: 525,
  [NEW_CONDO_BLACKBACK]: 500,
  [NEW_HOUSE_SILVERBACK]: 550,
  [NEW_CONDO_SILVERBACK]: 525,
  [NEW_HOUSE_KING_KONG]: 665,
  [NEW_CONDO_KING_KONG]: 575,
};
