import {
  LOAD_PLAN_OPTIONS,
  LOAD_PLAN_ADDONS,
  LOAD_PLAN_FEATURES,
  SET_SELECTED_PLAN,
  SET_SERVICE_CALL_FEE,
  CLEAR_CART,
  PLAN_TYPE,
} from "../actions/consumer";

const INITIAL_STATE = {
  options: [],
  features: [],
  addons: [],
  order: {
    planName: "kingkong",
    planIndex: 0,
    serviceCallFee: 75,
  },
  plantype: "monthly",
};

// selectors
export const getPlanOptions = (state) => state.options;
export const getPlanFeatures = (state) => state.features;
export const getPlanAddons = (state) => state.addons;
export const getOrder = (state) => state.order;
export const getPlantype = (state) => state.plantype;

// reducers
const actionHandlers = new Map([
  [LOAD_PLAN_OPTIONS, handleLoadPlanOptions],
  [LOAD_PLAN_FEATURES, handleLoadPlanFeatures],
  [LOAD_PLAN_ADDONS, handleLoadPlanAddons],
  [SET_SELECTED_PLAN, handlePlanSelection],
  [SET_SERVICE_CALL_FEE, handleFeeSelection],
  [CLEAR_CART, handleClearCart],
  [PLAN_TYPE, handlePlantype],
]);

function handleLoadPlanFeatures(state, action) {
  const { data } = action.payload;

  return data ? { ...state, features: data } : state;
}

function handleLoadPlanOptions(state, action) {
  const { data } = action.payload;

  return data ? { ...state, options: data } : state;
}

function handleLoadPlanAddons(state, action) {
  const { data } = action.payload;

  return data ? { ...state, addons: data } : state;
}

function handlePlanSelection(state, action) {
  const { plan, index } = action.payload;

  return plan
    ? {
        ...state,
        order: {
          ...state.order,
          planName: plan,
          planIndex: index,
        },
      }
    : state;
}

function handleFeeSelection(state, action) {
  const serviceCallFee = action.payload;

  return serviceCallFee > 0
    ? { ...state, order: { ...state.order, serviceCallFee } }
    : state;
}

function handleClearCart(state, action) {
  return {
    ...state,
    order: INITIAL_STATE.order,
  };
}
function handlePlantype(state, action) {
  console.log(action.payload);
  return {
    ...state,
    plantype: action.payload,
  };
}

const DataReducer = (state = INITIAL_STATE, action = { type: null }) =>
  actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;

export default DataReducer;
