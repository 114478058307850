import React from "react";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import Section from "components/common/section";
import Button from "components/common/button";
import { Link } from "@mui/material";
// import PlanOptionImage from 'assets/images/plan-options.png';
// import PlanOptionImage from "assets/images/plan-pricing.jpg";
// import PlanOptionImage from "assets/images/plan-pricing-1.png";
import PlanOptionImage from "assets/images/plan-pricing-2.jpg";

import * as Styled from "./styled";

const ComparePlans = ({ onClick, t, myRef }) => (
  <Section style={{ marginTop: 66 }}>
    <Grid
      container
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      ref={myRef}
    >
      <Grid item sm={8} xs={12}>
        <Typography variant="h2" color="primary" gutterBottom align="center">
          {t("home:comparePlans")}
        </Typography>
        <Typography variant="body1" color="primary" gutterBottom align="center">
          *Pricing For Homes Going Through A Real Estate Purchase
        </Typography>
      </Grid>
      <Grid item sm={4} xs={12} style={{ paddingLeft: "8px" }}>
        <Button
          sx={{ padding: "16px 32px" }}
          onClick={onClick}
          color="secondary"
          fullWidth
        >
          {t("downloadPlans")}
        </Button>
      </Grid>
      <Styled.Image src={PlanOptionImage} alt="Plan Options" />
    </Grid>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "black",
        paddingTop: "32px",
      }}
    >
      <Link
        underline="hover"
        // href="http://www.Megasphere.us/members"
        onClick={() =>
          window.open("http://www.Megasphere.us/members", "_blank")
        }
        variant="h2"
        sx={{ fontSize: "24px", cursor: "pointer" }}
      >
        {" "}
        See Our Preferred Vendors
      </Link>
    </div>
  </Section>
);

export default withTranslation(["common", "home"])(ComparePlans);
